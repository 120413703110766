import React, { Component } from "react";
import { Container, Panel, Table, Button, IconButton, ButtonToolbar, Input, Modal } from "rsuite";
const { Column, HeaderCell, Cell } = Table;
import { saveAs } from "file-saver";

import {
	EraIcon
} from "../../styles/Icon.js";


import {
	NAMED_KG_RINF,
	NAMED_KG_ERATV,
	NAMED_KG_ERA_VOCABULARY,
	NAMED_KG_ERA_SKOS,
	NAMED_KG_ERA_SHACL,
	SPARQL_ENDPOINT
} from "../../config/config.js";

export const DownloadSparqlBook = (props) => {

	let file = props.rowData.query_file;
	let description = props.rowData.description;

	const download = async () => {

		//console.log(file);

		let data = (await import('./sparqlbooks/' + file)).default;

		data = data.replaceAll("${{SPARQL_ENDPOINT}}", SPARQL_ENDPOINT)

		data = data.replaceAll("${{NAMED_KG_RINF}}", NAMED_KG_RINF)
		data = data.replaceAll("${{NAMED_KG_ERATV}}", NAMED_KG_ERATV);
		data = data.replaceAll("${{NAMED_KG_ERA_VOCABULARY}}", NAMED_KG_ERA_VOCABULARY);
		data = data.replaceAll("${{NAMED_KG_ERA_SKOS}}", NAMED_KG_ERA_SKOS);
		data = data.replaceAll("${{NAMED_KG_ERA_SHACL}}", NAMED_KG_ERA_SHACL);


		const blob = new Blob([data], { type: "text/plain;charset=utf-8" });

		saveAs(blob, file);

	}

	return (

		<Cell {...props} style={{ padding: "8px" }}>

			<IconButton
				appearance={"primary"}
				color={"green"}
				onClick={download}
				size={'sm'}
				placement={"right"}
				icon={<EraIcon faName={"file-export"} style={null} />}
			>
				Download
			</IconButton>
		</Cell>

	);

}

export const ViewQuery = (props) => {

	let file = props.rowData.query_file;
	let description = props.rowData.description;

	const [modalOpen, setModalOpen] = React.useState(false);
	const [modalData, setModalData] = React.useState('');

	const openModal = async () => {

		//console.log(file);

		let data = (await import('./queries/' + file)).default;

		data = data.replaceAll("${{SPARQL_ENDPOINT}}", SPARQL_ENDPOINT)

		data = data.replaceAll("${{NAMED_KG_RINF}}", NAMED_KG_RINF)
		data = data.replaceAll("${{NAMED_KG_ERATV}}", NAMED_KG_ERATV);
		data = data.replaceAll("${{NAMED_KG_ERA_VOCABULARY}}", NAMED_KG_ERA_VOCABULARY);
		data = data.replaceAll("${{NAMED_KG_ERA_SKOS}}", NAMED_KG_ERA_SKOS);
		data = data.replaceAll("${{NAMED_KG_ERA_SHACL}}", NAMED_KG_ERA_SHACL);

		setModalData(data);
		setModalOpen(true);

	}

	const closeModal = () => {

		setModalOpen(false);

	}

	const openYasgui = () => {

		//console.log(encodeURIComponent(modalData))

		let url = "/endpoint#query=" + encodeURIComponent(modalData)

		window.open(url, '_blank').focus();
	}

	const download = () => {

		const blob = new Blob([modalData], { type: "text/plain;charset=utf-8" });

		saveAs(blob, file);

	}


	return (
		<>
			<Modal open={modalOpen} onClose={closeModal} size={'lg'}>
				<Modal.Header>
					<Modal.Title>SPARQL query</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>{description}</p>
					<pre>
						<Input as="textarea" rows={15} value={modalData} />
					</pre>

					<ButtonToolbar>
						<div style={{ flexGrow: 1 }}></div>
						<IconButton
							onClick={download}
							appearance={"primary"}
							color={"green"}
							placement={"right"}

							icon={<EraIcon faName={"file-export"} style={null} />}
						>
							Save query
						</IconButton>
						<IconButton
							onClick={openYasgui}
							appearance={"primary"}
							color={"orange"}
							placement={"right"}

							icon={<EraIcon faName={"play"} style={null} />}
						>
							Execute query
						</IconButton>
					</ButtonToolbar>
				</Modal.Body>
			</Modal>

			<Cell {...props} style={{ padding: "8px" }}>

				<IconButton
					appearance={"primary"}
					color={"blue"}
					onClick={openModal}
					size={'sm'}
					placement={"right"}
					icon={<EraIcon faName={"eye"} style={null} />}
				>
					View query
				</IconButton>
			</Cell>
		</>
	)

}


export default class dataStories extends Component {



	constructor(props) {

		super(props);

		// Files from ./sparqlbooks/

		this.books = [


			{
				"description": "Notebook aggregating queries for checking the completeness of parameters per country.",
				"query_file": "completeness.sparqlbook"
			},
			{
				"description": "Notebook aggregating other queries.",
				"query_file": "otherqueries.sparqlbook"
			},
			{
				"description": "Notebook aggregating queries related to the TEN-T data space.",
				"query_file": "tentqueries.sparqlbook"
			}

		]

		// Files from ./queries/

		this.queries1 = [
			{
				"description": "Provide the number of operational points loaded in the Knowledge Graph, grouped by member state.",
				"query_file": "operational-points-per-member-state.sparql"
			},
			{
				"description": "Provide the number of sections of line loaded in the Knowledge Graph, grouped by member state.",
				"query_file": "sections-of-line-per-member-state.sparql"
			},
			/* deprecated{
				"description": "Provide the total length of lines loaded in the Knowledge Graph, grouped by member state.",
				"query_file": "length-of-lines-per-member-state.sparql"
			},*/
			/* deprecated {
				"description": "Provide the total length of lines (grouped by member state and type of line - currently section of line or tunnel).",
				"query_file": "length-of-lines-per-member-state-and-type.sparql"
			},*/
			{
				"description": "Provide the total length of lines (understood as sections of line) loaded in the Knowledge Graph, grouped by member state.",
				"query_file": "length-of-lines-per-member-state-only-sections-of-line.sparql"
			},
			// new user story 
			{
				"description": "Provide the total length of tunnels loaded in the Knowledge Graph, grouped by member state.",
				"query_file": "length-of-tunnels-per-member-state.sparql"
			},
		]
		this.queries2 = [
			{
				"description": "Provide a summary of the presence of the core parameter load capability (1.1.1.1.2.4) across all tracks per member state.",
				"query_file": "completeness-load-capability-1.sparql"
			},
			{
				"description": "Provide a summary of sections of lines and tracks where the core parameter load capability (1.1.1.1.2.4) does not appear (example with Spain).",
				"query_file": "completeness-load-capability-detail-ESP.sparql"
			},
			{
				"description": "Auxiliary query: Determine which core parameters are applicable to which types of RINF elements, e.g. OPs, SoLs, Track(s), according to the current Knowledge Graph.",
				"query_file": "auxiliary-query-core-parameters-elements.sparql"
			},
		]
		this.queries3 = [
			{
				"description": "Provide a summary of the presence of core parameters applicable to tracks per member state.",
				"query_file": "completeness-core-parameters-tracks-general.sparql"
			},
			{
				"description": "Provide all tracks where core parameters do not appear (example with Spain).",
				"query_file": "completeness-core-parameters-tracks-detail-ESP.sparql"
			},
		]
		this.queries4 = [
			{
				"description": "Provide a summary of the presence of core parameters applicable to sections of line per member state.",
				"query_file": "completeness-core-parameters-sol-general.sparql"
			},
			{
				"description": "Provide all sections of line where core parameters do not appear (example with Spain).",
				"query_file": "completeness-core-parameters-sol-detail-ESP.sparql"
			},
		]
		this.queries5 = [
			{
				"description": "Provide a summary of the presence of core parameters applicable to tunnels per member state.",
				"query_file": "completeness-core-parameters-tunnel-general.sparql"
			},
			{
				"description": "Provide all tunnels where core parameters do not appear (example with Spain).",
				"query_file": "completeness-core-parameters-tunnel-detail-ESP.sparql"
			},
		]
		this.queries6 = [
			{
				"description": "Provide a summary of the presence of core parameters applicable to sidings per member state. ",
				"query_file": "completeness-core-parameters-siding-general.sparql"
			},
			{
				"description": "Provide all the sidings where core parameters do not appear (example with Spain).",
				"query_file": "completeness-core-parameters-siding-detail-ESP.sparql"
			},
		]
		this.queries7 = [
			{
				"description": "Provide a summary of the presence of core parameters applicable to operational points per member state. ",
				"query_file": "completeness-core-parameters-op-general.sparql"
			},
			{
				"description": "Provide all operational points where core parameters do not appear (example with Spain).",
				"query_file": "completeness-core-parameters-op-detail-ESP.sparql"
			},
		]
		this.queries8 = [
			{
				"description": "Provide a summary of the presence of core parameters applicable to platforms per member state.",
				"query_file": "completeness-core-parameters-platform-general.sparql"
			},
			{
				"description": "Provide all platforms where core parameters do not appear (example with Spain). ",
				"query_file": "completeness-core-parameters-platform-detail-ESP.sparql"
			},
		]
		this.queries9 = [
			{
				"description": "Provide a summary of the presence of core parameters applicable to tracks' contact line systems per member state, without checking parameter applicability.",
				"query_file": "completeness-core-parameters-contactlinesystem-general.sparql"
			},
			{
				"description": "Provide a summary of the presence of core parameters applicable to tracks' contact line systems per member state, checking parameter applicability.",
				"query_file": "completeness-core-parameters-applicable-contactlinesystem-general.sparql"
			},
			{
				"description": "Provide all the tracks and contact line systems where core parameters do not appear, without checking parameter applicability (example with Spain).",
				"query_file": "completeness-core-parameters-contactlinesystem-detail-ESP.sparql"
			},
			{
				"description": "Provide all the tracks and contact line systems where core parameters do not appear, checking parameter applicability (example with Spain). ",
				"query_file": "completeness-core-parameters-applicable-contactlinesystem-detail-ESP.sparql"
			},
		]
		this.queries10 = [
			{
				"description": "Provide a summary of the presence of core parameters applicable to tracks' train detection systems per member state.",
				"query_file": "completeness-core-parameters-traindetectionsystem-general.sparql"
			},
			{
				"description": "Provide all the tracks and train detection systems where core parameters do not appear (example with Spain).",
				"query_file": "completeness-core-parameters-traindetectionsystem-detail-ESP.sparql"
			},
		]
		this.queries11 = [
			{
				"description": "Number of tracks, per country, that are not TSI compliant.",
				"query_file": "tracks-per-country-tracks-nonTSIcompliant.sparql"
			},
			{
				"description": "Number of tracks, per country, whose associated train detection systems are not TSI compliant.",
				"query_file": "tracks-per-country-traindetectionsystems-nonTSIcompliant.sparql"
			},
			{
				"description": "Types of gauging profiles of tracks in neighbouring countries to Ukraine.",
				"query_file": "tracks-neighbouring-countries-Ukraine.sparql"
			},
			{
				"description": "Identify unreachable Operational Points.",
				"query_file": "unreachable-operational-points.sparql"
			},
			{
				"description": "Identify sink Operational Points. ",
				"query_file": "unreachable-operational-points.sparql"
			},
			{
				"description": "Identify disconnected Operational Points.",
				"query_file": "disconnected-operational-points.sparql"
			},
			{
				"description": "Query that allows generating INSPIRE-related data according to the INSPIRE ontologies.",
				"query_file": "inspire-data-generation.sparql"
			},
		]


		this.queries12 = [
			{
				"description": "Determine the compliance of tracks of a specific section of line, with the High Speed Load Model (HSLM) - era:highSpeedLoadModelCompliance, RINF index 1.1.1.1.2.4.2.",
				"query_file": "ten-t-1-highSpeedLoadModelCompliance.sparql"
			},
			{
				"description": "Classification of a specific section of line according to the INF TSI - era:lineCategory, RINF index 1.2.1.0.2.2.",
				"query_file": "ten-t-2-lineReference.sparql"
			},
			{
				"description": "Number of tracks for a specific section of line.",
				"query_file": "ten-t-3-numberofTracks.sparql"
			},
			{
				"description": "Type of contact line systems of the tracks in a specific section of line.",
				"query_file": "ten-t-4-contactlinesystems.sparql"
			},
			{
				"description": "Wheel set gauge of the tracks in a specific section of line.",
				"query_file": "ten-t-5-wheelSetGauge.sparql"
			},
			{
				"description": "Energy supply system associated to the tracks in a specific section of line.",
				"query_file": "ten-t-6-energySupplySystem.sparql"
			},
			{
				"description": "ERTMS / ETCS application level of tracks in a specific section of line, which expresses the possible operating relationships between track and train.",
				"query_file": "ten-t-7-etcsLevelType.sparql"
			},
			{
				"description": "ETCS baseline installed in the tracks of a specific section of line.",
				"query_file": "ten-t-8-etcsBaseline.sparql"
			},
			{
				"description": "Location of particular points requiring specific checks due to deviations from gauging, for tracks in a specific section of line.",
				"query_file": "ten-t-9-gaugingCheckLocation.sparql"
			},
			{
				"description": "Combined transport profile for semi-trailers, for tracks in a specific section of line.",
				"query_file": "ten-t-10-profileNumSemiTrailers.sparql"
			},
			{
				"description": "If ETCS in operation, fill in parameters ETCS Baseline and ETCS Level, for tracks in a specific section of line.",
				"query_file": "ten-t-11-etcsBaselineAndLevel.sparql"
			},
			{
				"description": "GSM-R version installed in tracks of a specific section of line.",
				"query_file": "ten-t-12-gsmrVersion.sparql"
			},
			{
				"description": "Maximum Axle Load for tracks in a specific section of line (Note that this parameter does not exist. Therefore minAxleLoad is used, currently returns no values). ",
				"query_file": "ten-t-13-minAxleLoad.sparql"
			},
			{
				"description": "Nominal maximum operational speed on a specific section of line as a result of INF, ENE and CCS subsystem characteristics expressed in kilometres/hour.",
				"query_file": "ten-t-14-maximumPermittedSpeed.sparql"
			},

		]
	}




	render() {



		return (

			<Container style={{ padding: "25px" }}>

				<Panel header={<h2>Data Stories</h2>}>
					<p align="justify">
						Here's a catalogue of SPARQL queries (together with their correspondence in natural language) that can be useful for different purposes.
						Queries will be provided as independent files inside this folder, to facilitate their maintenance and the generation of
						references to them from other parts of the ERA ecosystem (e.g., ontology documentation, demo sites, etc.),
						especially in case of ontology changes. And some of these queries will also be grouped together in SPARQL notebooks,
						for convenience, providing a unified view of a specific need (e.g., parameter completeness reports per member state).
					</p>
				</Panel>

				<Panel style={{ margin: "15px" }} header={<h4>Queries used to retrieve total number of elements and their length</h4>} bordered defaultExpanded>

					<Table autoHeight data={this.queries1}>

						<Column flexGrow={5} align="left" fixed>
							<HeaderCell>Description</HeaderCell>
							<Cell dataKey="description" />
						</Column>

						<Column flexGrow={1} align="right" fixed>
							<HeaderCell></HeaderCell>
							<ViewQuery dataKey="query" />
						</Column>

					</Table>

				</Panel>

				<Panel style={{ margin: "15px" }} header={<h4>Queries for Core Parameters</h4>} bordered defaultExpanded>

					<Table autoHeight data={this.queries2}>

						<Column flexGrow={5} align="left" fixed>
							<HeaderCell>Description</HeaderCell>
							<Cell dataKey="description" />
						</Column>

						<Column flexGrow={1} align="right" fixed>
							<HeaderCell></HeaderCell>
							<ViewQuery dataKey="query" />
						</Column>

					</Table>

				</Panel>


				<Panel style={{ margin: "15px" }} header={<h4>Queries for Tracks</h4>} bordered defaultExpanded>

					<Table autoHeight data={this.queries3}>

						<Column flexGrow={5} align="left" fixed>
							<HeaderCell>Description</HeaderCell>
							<Cell dataKey="description" />
						</Column>

						<Column flexGrow={1} align="right" fixed>
							<HeaderCell></HeaderCell>
							<ViewQuery dataKey="query" />
						</Column>

					</Table>

				</Panel>
				<Panel style={{ margin: "15px" }} header={<h4>Queries for Sections of Line</h4>} bordered defaultExpanded>

					<Table autoHeight data={this.queries4}>

						<Column flexGrow={5} align="left" fixed>
							<HeaderCell>Description</HeaderCell>
							<Cell dataKey="description" />
						</Column>

						<Column flexGrow={1} align="right" fixed>
							<HeaderCell></HeaderCell>
							<ViewQuery dataKey="query" />
						</Column>

					</Table>

				</Panel>
				<Panel style={{ margin: "15px" }} header={<h4>Queries for Tunnels</h4>} bordered defaultExpanded>

					<Table autoHeight data={this.queries5}>

						<Column flexGrow={5} align="left" fixed>
							<HeaderCell>Description</HeaderCell>
							<Cell dataKey="description" />
						</Column>

						<Column flexGrow={1} align="right" fixed>
							<HeaderCell></HeaderCell>
							<ViewQuery dataKey="query" />
						</Column>

					</Table>

				</Panel>
				<Panel style={{ margin: "15px" }} header={<h4>Queries for Sidings</h4>} bordered defaultExpanded>

					<Table autoHeight data={this.queries6}>

						<Column flexGrow={5} align="left" fixed>
							<HeaderCell>Description</HeaderCell>
							<Cell dataKey="description" />
						</Column>

						<Column flexGrow={1} align="right" fixed>
							<HeaderCell></HeaderCell>
							<ViewQuery dataKey="query" />
						</Column>

					</Table>

				</Panel>
				<Panel style={{ margin: "15px" }} header={<h4>Queries for Operational Points</h4>} bordered defaultExpanded>

					<Table autoHeight data={this.queries7}>

						<Column flexGrow={5} align="left" fixed>
							<HeaderCell>Description</HeaderCell>
							<Cell dataKey="description" />
						</Column>

						<Column flexGrow={1} align="right" fixed>
							<HeaderCell></HeaderCell>
							<ViewQuery dataKey="query" />
						</Column>

					</Table>

				</Panel>

				<Panel style={{ margin: "15px" }} header={<h4>Queries for Platforms</h4>} bordered defaultExpanded>

					<Table autoHeight data={this.queries8}>

						<Column flexGrow={5} align="left" fixed>
							<HeaderCell>Description</HeaderCell>
							<Cell dataKey="description" />
						</Column>

						<Column flexGrow={1} align="right" fixed>
							<HeaderCell></HeaderCell>
							<ViewQuery dataKey="query" />
						</Column>

					</Table>

				</Panel>

				<Panel style={{ margin: "15px" }} header={<h4>Queries for Contact Line Systems</h4>} bordered defaultExpanded>

					<Table autoHeight data={this.queries9}>

						<Column flexGrow={5} align="left" fixed>
							<HeaderCell>Description</HeaderCell>
							<Cell dataKey="description" />
						</Column>

						<Column flexGrow={1} align="right" fixed>
							<HeaderCell></HeaderCell>
							<ViewQuery dataKey="query" />
						</Column>

					</Table>

				</Panel>


				<Panel style={{ margin: "15px" }} header={<h4>Queries for Train Detection Systems</h4>} bordered defaultExpanded>

					<Table autoHeight data={this.queries10}>

						<Column flexGrow={5} align="left" fixed>
							<HeaderCell>Description</HeaderCell>
							<Cell dataKey="description" />
						</Column>

						<Column flexGrow={1} align="right" fixed>
							<HeaderCell></HeaderCell>
							<ViewQuery dataKey="query" />
						</Column>

					</Table>

				</Panel>


				<Panel style={{ margin: "15px" }} header={<h4>Queries that have been collected from ERA stakeholders</h4>} bordered defaultExpanded>

					<Table autoHeight data={this.queries11}>

						<Column flexGrow={5} align="left" fixed>
							<HeaderCell>Description</HeaderCell>
							<Cell dataKey="description" />
						</Column>

						<Column flexGrow={1} align="right" fixed>
							<HeaderCell></HeaderCell>
							<ViewQuery dataKey="query" />
						</Column>

					</Table>

				</Panel>


				<Panel style={{ margin: "15px" }} header={<h4>Queries related to the TEN-T data space</h4>} bordered defaultExpanded>

					<Table autoHeight data={this.queries12}>

						<Column flexGrow={5} align="left" fixed>
							<HeaderCell>Description</HeaderCell>
							<Cell dataKey="description" />
						</Column>

						<Column flexGrow={1} align="right" fixed>
							<HeaderCell></HeaderCell>
							<ViewQuery dataKey="query" />
						</Column>

					</Table>

				</Panel>






				<Panel style={{ margin: "15px" }} header={<h4>SPARQL Notebooks</h4>} bordered defaultExpanded>

					<Table autoHeight data={this.books}>

						<Column flexGrow={5} align="left" fixed>
							<HeaderCell>Description</HeaderCell>
							<Cell dataKey="description" />
						</Column>

						<Column flexGrow={1} align="right" fixed>
							<HeaderCell></HeaderCell>
							<DownloadSparqlBook dataKey="query" />
						</Column>

					</Table>

				</Panel>


			</Container>
		)
	}
}
